import { z } from 'zod';

export const clientEnvSchema = z.object({
  NEXT_PUBLIC_SENTRY_DSN: z.string().optional(),
  NEXT_PUBLIC_ENV: z.string(),
  NEXT_PUBLIC_SENTRY_DEBUG: z.coerce.boolean().optional().default(false),
  NEXT_PUBLIC_DAVINCI_ASSETS_CDN: z.string().default('https://cdn.davinci.care'),
  NEXT_PUBLIC_DAVINCI_WEBAPP_BASE_URL: z.string(),
  ELTY_PUBLIC_URL: z.string().default('https://elty.it'),
  NEXT_PUBLIC_GTM_ID: z.string().optional(),
  NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN: z.string(),
  NEXT_PUBLIC_ALGOLIA_APP_ID: z.string(),
  NEXT_PUBLIC_ALGOLIA_API_KEY: z.string(),
  NEXT_PUBLIC_ALGOLIA_CLINICS_INDEX: z.string(),
  NEXT_PUBLIC_STORYBLOK_VERSION: z.enum(['published', 'draft']),
  NEXT_PUBLIC_STORYBLOK_TOKEN_DRAFT: z.string(),
  NEXT_PUBLIC_STORYBLOK_PUBLISHED_TOKEN: z.string(),
  NEXT_PUBLIC_DAVINCI_CORE_URL: z.string(),
  NEXT_PUBLIC_ELTY_MMG_PUBLIC_URL: z.string(),
  NEXT_PUBLIC_ELTY_PUBLIC_URL: z.string(),
});

export const clientProcessEnv = {
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
  NEXT_PUBLIC_SENTRY_DEBUG: process.env.NEXT_PUBLIC_SENTRY_DEBUG,
  NEXT_PUBLIC_DAVINCI_ASSETS_CDN: process.env.NEXT_PUBLIC_DAVINCI_ASSETS_CDN,
  NEXT_PUBLIC_DAVINCI_WEBAPP_BASE_URL: process.env.NEXT_PUBLIC_DAVINCI_WEBAPP_BASE_URL,
  ELTY_PUBLIC_URL: process.env.ELTY_PUBLIC_URL,
  NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN: process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN,
  NEXT_PUBLIC_ALGOLIA_APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  NEXT_PUBLIC_ALGOLIA_API_KEY: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
  NEXT_PUBLIC_ALGOLIA_CLINICS_INDEX: process.env.NEXT_PUBLIC_ALGOLIA_CLINICS_INDEX,
  NEXT_PUBLIC_STORYBLOK_VERSION: process.env.NEXT_PUBLIC_STORYBLOK_VERSION,
  NEXT_PUBLIC_STORYBLOK_TOKEN_DRAFT: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN_DRAFT,
  NEXT_PUBLIC_STORYBLOK_PUBLISHED_TOKEN: process.env.NEXT_PUBLIC_STORYBLOK_PUBLISHED_TOKEN,
  NEXT_PUBLIC_DAVINCI_CORE_URL: process.env.NEXT_PUBLIC_DAVINCI_CORE_URL,
  NEXT_PUBLIC_ELTY_MMG_PUBLIC_URL: process.env.NEXT_PUBLIC_ELTY_MMG_PUBLIC_URL,
  NEXT_PUBLIC_ELTY_PUBLIC_URL: process.env.NEXT_PUBLIC_ELTY_PUBLIC_URL,
};

export const clientEnv = clientEnvSchema.parse(clientProcessEnv);
